<template>
  <div >
  <notification :message="message"></notification>
    <on-site-id-top-header-template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo" @responseLoaded="getDetails">

                <div slot-scope="{ response, logo }">
                    <loading v-if="!response"></loading>
                    <div v-else>
                        <div v-if="response.body.properties.get('name')" class="container mx-auto px-4 sm:px-6 lg:px-8">
                            <div class="bg-secondary-appdarkblue opacity-75 rounded-xl mt-8 max-h-100 p-4 border-2 border-secondary-appdarkblue w-full">
                                <div class="mx-auto container">
                                    <dt class="text-base font-extrabold text-white">Name</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('name')}}
                                    </dd>
                                </div>
                            </div>
                            <div class="bg-secondary-appdarkblue opacity-75 rounded-xl mt-8 max-h-100 p-4 border-2 border-secondary-appdarkblue w-full">
                                <div class="mx-auto container">
                                    <dt class="text-base font-extrabold text-white">Email</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('email')}}
                                    </dd>
                                </div>
                            </div>
                            <div class="bg-secondary-appdarkblue opacity-75 rounded-xl mt-8 max-h-100 p-4 border-2 border-secondary-appdarkblue w-full">
                                <div class="mx-auto container">
                                    <dt class="text-base font-extrabold text-white">Contact Number</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('contact_number')}}
                                    </dd>
                                </div>
                            </div>
                            <div v-if="response.body.properties.get('date_of_birth')" class="bg-secondary-appdarkblue opacity-75 rounded-xl mt-8 max-h-100 p-4 border-2 border-secondary-appdarkblue w-full">
                                <div class="mx-auto container">
                                    <dt class="text-base font-bold text-white">Date Of Birth</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('date_of_birth')}}
                                    </dd>
                                </div>
                            </div>

                            <div class="bg-secondary-appdarkblue opacity-70 rounded-xl mt-8 max-h-100 p-4 border-2 border-secondary-appdarkblue w-full"
                                 v-if="response.body.properties.get('medical_conditions') || response.body.properties.get('blood_type') || response.body.properties.get('allergies') || response.body.properties.get('need_to_know_information')">
                                <div class="mx-auto container">
                                    <dt class="text-xl text-center font-bold text-white">Emergency Information</dt>
                                </div>
                                <div class="pt-4 mx-auto container" v-if="response.body.properties.get('medical_conditions')">
                                    <dt class="text-base font-bold text-white">Medical Conditions</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('medical_conditions')}}
                                    </dd>
                                </div>
                                <div class="pt-4 mx-auto container" v-if="response.body.properties.get('blood_type')">
                                    <dt class="text-base font-bold text-white">Blood Type</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('blood_type')}}
                                    </dd>
                                </div>
                                <div class="pt-4 mx-auto container" v-if="response.body.properties.get('allergies')">
                                    <dt class="text-base font-bold text-white">Allergies</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('allergies')}}
                                    </dd>
                                </div>
                                <div class="pt-4 mx-auto container" v-if="response.body.properties.get('need_to_know_information')">
                                    <dt class="text-base font-bold text-white">Need To Know Information</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('need_to_know_information')}}
                                    </dd>
                                </div>
                            </div>

                            <div class="bg-secondary-appdarkblue opacity-70 rounded-xl mt-8 max-h-100 p-4 border-2 border-secondary-appdarkblue w-full"
                                 v-if="response.body.properties.get('employer_name')">
                                <div class="mx-auto container">
                                    <dt class="text-xl text-center font-bold text-white">Employer</dt>
                                </div>
                                <div class="pt-4 mx-auto container" v-if="response.body.properties.get('employer_name')">
                                    <dt class="text-base font-bold text-white">On Site For {{response.body.properties.get('employer_name')}}</dt>
                                    <div class="text-center w-full mt-2"><img :src="getLogo(response)"></div>
                                </div>

                            </div>

                            <a-button class="my-3 mt-6 w-full" @click.native="displaySettings=true">Update Details</a-button>
                            <update-on-site-id-form v-if="onSiteIdResource" :displayModal="displayOnSiteIdDetails" :showButton="true" additionalButtonClasses="my-3 mb-6 w-full" :action="onSiteIdResource.actions.filter(action => action.name==='update-on-site-id').first()" :actionKey="'update-on-site-id'" :onSuccessPath="$router.currentRoute.fullPath" @displayModal="displayOnSiteIdDetails = true" @removeFromDisplay="displayOnSiteIdDetails = false" title="Update On Site ID Details"></update-on-site-id-form>
                        </div>
                    </div>
                </div>

            </fetch-siren-root>
            <settings-slide-over v-if="displaySettings" :title="`Settings`" :data-cy="`button-settings`" :submitting="submittingModal"
                                 @removeFromDisplay="resetSettings()" @perform="perform()" class="settings-slide-over">
                <property-or-field class="mt-4" title="First Name" :action="userEditAction" fieldId="first_name" v-model="formEditData['first_name']" scope="user-details"></property-or-field>
                <property-or-field class="mt-4" title="Surname" :action="userEditAction" fieldId="surname" v-model="formEditData['surname']" scope="user-details"></property-or-field>
                <property-or-field class="mt-4" title="Email" :action="userEditAction" fieldId="email" v-model="formEditData['email']" scope="user-details"></property-or-field>
                <property-or-field class="mt-4" title="Contact Number" :action="userEditAction" fieldId="contact_number" v-model="formEditData['contact_number']" scope="user-details"></property-or-field>
                <property-or-field class="mt-4" title="Profile Image" :action="userEditAction" fieldId="profile_image" v-model="formEditData['profile_image']" scope="user-details"></property-or-field>
                <property-or-field class="mt-4" title="Background Image" :action="userEditAction" fieldId="background_image" v-model="formEditData['background_image']" scope="user-details"></property-or-field>
                <property-or-field class="mt-4" title="Contact Preference" :action="userEditAction" fieldId="contact_preference" v-model="formEditData['contact_preference']" scope="user-details"></property-or-field>
                <property-or-field class="mt-4" title="Exclude From Address Book" :action="userEditAction" fieldId="exclude_from_address_book" v-model="formEditData['exclude_from_address_book']" scope="user-details"></property-or-field>
                <property-or-field class="mt-4" title="Sort Users By" :action="userEditAction" fieldId="sort_users_by" v-model="formEditData['sort_users_by']" scope="user-details"></property-or-field>

                <button v-if="showPasswordField === false" @click="showPasswordField = true" data-cy="edit-password" type="button" class="inline-flex mr-3 mt-4 justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    Edit Password
                </button>

                <property-or-field class="mt-4" v-if="showPasswordField" title="Current Password" :action="userResetPasswordAction" fieldId="current_password" v-model="formPasswordData['current_password']" scope="password"></property-or-field>
                <property-or-field class="mt-4" v-if="showPasswordField" title="Password" :action="userResetPasswordAction" fieldId="password" v-model="formPasswordData['password']" scope="password"></property-or-field>

            </settings-slide-over>
        </template>
    </on-site-id-top-header-template>
  </div>
</template>

<script>
import AssignOnSiteIdAction from "@/v2/components/AssignOnSiteIdAction.vue";
import Button from "@/v2/buttons/Button.vue";
import ConfirmationAction from "@/v2/components/ConfirmationAction.vue";
import FetchSirenRoot from "@/components/FetchSirenRoot.vue";
import Loading from "@/components/Loading.vue";
import { mapActions, mapGetters } from "vuex";
import Notification from "@/components/Notification.vue";
import OnSiteIdActions from "@/v2/components/OnSiteIdActions.vue";
import OnSiteIdTopHeaderTemplate from "@/templates/OnSiteIdTopHeaderTemplate.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";
import Siren from "super-siren";
import SettingsSlideOver from "@/components/SettingsSlideOver.vue";
import TopHeaderTemplate from "@/templates/TopHeaderTemplate.vue";
import UpdateOnSiteIdForm from "@/v2/components/forms/UpdateOnSiteIdForm.vue";

export default {
  inject: ["$validator"],
  components: {
      UpdateOnSiteIdForm,
      OnSiteIdActions,
      PropertyOrField,
      SettingsSlideOver,
      AssignOnSiteIdAction,
      'a-button': Button,
      ConfirmationAction,
      OnSiteIdTopHeaderTemplate,
      Loading,
      FetchSirenRoot,
      TopHeaderTemplate,
      Notification },

  data() {
    return {
        displaySettings:false,
        userEditAction:null,
        userResetPasswordAction:null,
        showPasswordField: false,
        submittingModal: false,
        onSiteIdResource: null,
        displayOnSiteIdDetails: false
    };
  },
  computed: {
      linkName() {
          return null;
      },
      link(){
          // if(Object.keys(this.$route.query).length === 0){
          //     return '/orders';
          // }
          return this.$route.fullPath.replace('/v3','')
      },
    ...mapGetters({
         accessToken: "getAccessToken",
        message: "getMessage",
        messageWithoutTimeout: "getMessageWithoutTimeout"
    }),
      formEditData() {
          if (!this.userEditAction.fields) {
              return {};
          }
          var payload = {};
          this.userEditAction.fields.forEach(function (field) {
              if (field.value !== undefined) payload[field.name] = field.value;
          });
          return payload;
      },
      formPasswordData() {
          if (!this.userResetPasswordAction.fields) {
              return {};
          }
          var payload = {};
          this.userResetPasswordAction.fields.forEach(function (field) {
              if (field.value !== undefined) payload[field.name] = field.value;
          });
          return payload;
      },
  },
  mounted() {
    // if (this.accessToken !== null) {
    //   Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
    //   return Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
    //     this.$router.push({ path: "/" });
    //   });
    // }

  },

  methods: {
      setOnSiteId(response){
          let follow = Siren.Helper.follow;
          Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

          Siren.get(process.env.VUE_APP_API_URL + "/api/on-site-id").then(res => {
              this.onSiteIdResource = res.body.entities.filter(entity => entity.rels.contains('on-site-id')).first().entity;
          }, this)

      },
      getDetails(response){
          this.userEditAction = response.body.findActionByName('update-user');
          this.userResetPasswordAction= response.body.findActionByName('reset-user-password');
          this.setOnSiteId(response);
      },
      hasLink(response, linkName){
          return response.body.links.filter(link => link.rels.contains(linkName)).first();
      },
      navigateToProfile(response) {
          let selfLink = response.body.links.filter(link => link.rels.contains('profile')).first().href;
          let parts = selfLink.split('/api');
          this.$router.push(parts[1]);
      },
      navigateToMyOnSiteId(response) {
          let selfLink = response.body.links.filter(link => link.rels.contains('my-on-site-id')).first().href;
          let parts = selfLink.split('/api');
          this.$router.push(parts[1]);
      },
      navigateToEmployerSettings(response) {
          let selfLink = response.body.links.filter(link => link.rels.contains('employer-settings')).first().href;
          let parts = selfLink.split('/api');
          this.$router.push(parts[1]);
      },
      resetSettings(){
          this.showPasswordField = false;
          this.displaySettings = false;
          this.formEditData.name = this.userEditAction.fields.get('name').value;
          this.formEditData.email = this.userEditAction.fields.get('email').value;
          this.formEditData.contact_number = this.userEditAction.fields.get('contact_number').value;
          this.formPasswordData.password = '';
          this.formEditData.profile_image = '';
          this.errors.clear("user-details");
          this.errors.clear("password");
      },
      perform() {
          this.displaySettings = true;
          this.submittingModal = true;
          this.errors.clear("user-details");
          this.errors.clear("password");
          this.userEditAction.perform(this.formEditData).then(res => {
              if (this.showPasswordField) {
                  this.userResetPasswordAction.perform(this.formPasswordData).then(res => {
                      this.successfullySubmitted(res);
                  }).catch(error => {
                      this.submittingModal = false;
                      if(error.status !== 422) {
                          alert('There was an error processing your request.' + error);
                      }
                      this.$setLaravelValidationErrorsFromResponse(
                          JSON.parse(error.response.text),
                          'password'
                      );
                      this.$emit('failure');
                  })
              }else{
                  this.successfullySubmitted(res);
              }
          }).catch(error => {
              this.submittingModal = false;
              if(error.status !== 422) {
                  alert('There was an error processing your request.' + error);
              }
              this.$setLaravelValidationErrorsFromResponse(
                  JSON.parse(error.response.text),
                  "user-details"
              );
              this.$emit('failure');
          });
      },
      successfullySubmitted(res){
          this.displaySettings = false;
          this.submittingModal = false;
          this.$router.go(0);
          this.$emit('success', res);
      },
      closeDisplaySettigns()
      {
          this.displaySettings = false;
      },
      getLogo(response){
          let logo = response.body.properties.get('employer_logo');
          if (logo !== null) {
              if (logo && logo.startsWith('public/')) {
                  logo = logo.replace('public/', '');
              }
              logo = process.env.VUE_APP_API_URL + '/storage/logo/' + logo
          }
          return logo;
      }
  },
};
</script>

<style>
body{
    background-color:red
}
*{
    margin:0;
}
</style>
